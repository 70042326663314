.priceContainer {
  display: flex;
  flex-direction: column;

  &[data-variant='carWithFinance'] {
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
  }

  & .totalPrice {
    color: var(--color-neutral-700);
    margin: 0;

    &[data-variant='carTile'] {
      font-size: 16px;
    }

    &[data-variant='carTileWithFinance'] {
      font-size: 14px;
      font-weight: 500;
    }
  }

  & .inlineVAT {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: flex-end;
  }

  & .vat {
    margin: 0;
  }
}
